<template>
  <div>
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <h4>ChatOne</h4>
              </div>
                 <b-alert show variant="danger" v-if="isLoginFail">
                  {{ $t('invalid_login')}}
                </b-alert>
              <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                              class="mb-3"
                              name="username"
                              :rules="{ required: true }"
                              prepend-icon="ni ni-circle-08"
                              placeholder="Username"
                              @input="isLoginFail = false"
                              v-model="credentials.username">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              autocomplete
                              name="Password"
                              :rules="{ required: true }"
                              prepend-icon="ni ni-lock-circle-open"
                              type="password"
                              placeholder="Password"
                              @input="isLoginFail = false"
                              v-model="credentials.password">
                  </base-input>

                  <!-- <b-form-checkbox v-model="credentials.remember_me">Remember me</b-form-checkbox> -->
                  <div class="text-center">
                    <base-button type="primary" native-type="submit" class="my-4">Sign in</base-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
  import { mapActions } from 'vuex';

  export default {
    data() {
      return {
        credentials: {
          username: 'admin',
          password: '123456',
        },
        isLoginFail: false,
      };
    },
    mounted() {
      console.log('log env', process.env)
    },
    methods: {
      ...mapActions({
        login: 'auth/login'
      }),
      onSubmit() {
        this.login(this.credentials);
      }
    },
  };
</script>
